<template>
  <ui-container
    tag="section"
    spacing="main"
    class="flex-1"
    no-gutters-x
  >
    <div class="mb-2.5 lg:mb-12 flex items-center lg:gap-4 gap-2.5">
      <ui-back-link />
      <ui-typography
        text="Notifications"
        variant="h3"
        tag="h1"
      />
    </div>
    <ui-card
      padding="custom"
      rounded="md"
      class="flex-1 h-full overflow-hidden"
    >
      <common-notifications-list />
    </ui-card>
  </ui-container>
</template>
