<template>
  <div
    ref="refWrap"
    class="flex flex-1 overflow-hidden relative"
  >
    <div
      ref="actionsRef"
      class="absolute right-0 top-0 bottom-0 flex"
    >
      <slot name="action" />
    </div>
    <div
      class="transition flex-1 relative z-1"
      :style="contentStyles"
    >
      <slot :swiped="swiped" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{ otherSwiped?: boolean }>();
  const refWrap = ref<HTMLDivElement | null>(null);
  const actionsRef = ref<HTMLDivElement | null>(null);
  const swipeRight = ref(0);
  const swiped = ref(false);
  const actionsWidth = computed(() => actionsRef.value?.offsetWidth || 0);
  const emit = defineEmits(['swipeEnd', 'swipeStart']);

  const contentStyles = computed(() => {
    return swipeRight.value ? { transform: `translate3d(${swipeRight.value}px, 0, 0)` } : undefined;
  });

  const resetSwipe = () => {
    swipeRight.value = 0;
    swiped.value = false;
  };

  const { lengthX } = useSwipe(refWrap, {
    // passive: false,
    onSwipe() {
      if (lengthX.value > 0) {
        swipeRight.value = Math.max(-lengthX.value, -actionsWidth.value);
        swiped.value = true;
        emit('swipeStart');
        return;
      }
      resetSwipe();
    },
    onSwipeEnd() {
      if (lengthX.value > 0 && actionsWidth.value && lengthX.value / actionsWidth.value >= 0.5) {
        swipeRight.value = -actionsWidth.value;
        swiped.value = true;
        emit('swipeEnd', true);
        return;
      }
      resetSwipe();
      emit('swipeEnd', false);
    }
  });

  watch(
    () => props.otherSwiped,
    val => {
      if (val) {
        resetSwipe();
      }
    }
  );
</script>

<style lang="scss" scoped>
  .transition {
    transition: transform 0.2s;
  }
</style>
