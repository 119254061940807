<template>
  <ui-card
    padding="sm"
    color="notify"
    :active="!isRead"
    rounded="sm"
    class="relative"
    :loading="loading"
    @click.passive="onClickCard"
  >
    <ui-typography no-wrap>
      <i18n-t
        :keypath="notificationMessage"
        scope="global"
      >
        <template
          v-if="room"
          #room
        >
          <ui-button
            hide-space
            variant="link"
            direct="left"
            :to="{
              name: 'dashboard-rooms-roomId',
              params: {
                roomId: room.id
              }
            }"
            class="!text-left"
            @click="onReadNotify"
          >
            {{ room.name }}
          </ui-button>
        </template>
        <template
          v-if="authorName"
          #author
        >
          <ui-button
            hide-space
            variant="link"
            direct="left"
            :to="{
              name: 'profile-username',
              params: {
                username: authorUsername
              }
            }"
            class="!text-left"
            @click="onReadNotify"
          >
            {{ authorName }}
          </ui-button>
        </template>
        <template
          v-if="mentionTo"
          #mention
        >
          <ui-button
            hide-space
            variant="link"
            direct="left"
            :to="mentionTo"
            class="!text-left"
            @click="onReadNotify"
          >
            {{ room.name }}
          </ui-button>
        </template>
      </i18n-t>
    </ui-typography>
    <ui-typography
      variant="small"
      class="text-right text-gray-300"
    >
      {{ timestamp }}
    </ui-typography>
  </ui-card>
</template>

<script setup lang="ts">
  type Props = {
    notification: NotificationItem;
    isRead: boolean;
    loading?: boolean;
  };
  const props = defineProps<Props>();
  const emits = defineEmits(['read']);
  const { formatTimestamp } = useFormatDate();
  const timestamp = computed(() => formatTimestamp(props.notification.updated_at));
  const data = computed(() => props.notification.data);
  const room = computed(() => {
    return ('room' in data.value ? data.value.room : data.value) as RoomPreview;
  });
  const author = computed<MessageAuthor | null>(() => {
    if ('author' in data.value) {
      return data.value.author as MessageAuthor;
    }
    if ('requested_user' in data.value) {
      return data.value.requested_user as MessageAuthor;
    }
    return null;
  });
  const authorUsername = computed(() => author.value?.username || null);
  const authorName = computed(() => getUserFullName(author.value));
  const { routerPush } = useRouterLocale();
  const mentionTo = computed(() => {
    if (
      [NotificationType.MentionedInRoomMessage, NotificationType.RepliedInRoomMessage].includes(
        props.notification.type
      ) &&
      room.value?.id
    ) {
      return {
        name: 'dashboard-rooms-roomId-chat',
        params: {
          roomId: room.value.id
        },
        query: {
          to: data.value.id
        }
      };
    }
    return null;
  });
  const notificationMessage = computed(() => {
    return {
      [NotificationType.MentionedInRoomMessage]: MessagesNotification.mentionedInRoomMessage,
      [NotificationType.RepliedInRoomMessage]: MessagesNotification.repliedInRoomMessage,
      [NotificationType.RoomTypeChanged]: MessagesNotification.roomTypeChanged,
      [NotificationType.RoomRequestApproved]: MessagesNotification.roomRequestApproved,
      [NotificationType.RoomRequestRejected]: MessagesNotification.roomRequestRejected,
      [NotificationType.RoomRequestCreated]: MessagesNotification.roomRequestCreated
    }[props.notification.type];
  });

  const onReadNotify = () => {
    emits('read');
  };

  const onClickCard = () => {
    if (mentionTo.value) {
      routerPush(mentionTo.value);
      return;
    }
    if (room.value?.id) {
      routerPush({
        name: 'dashboard-rooms-roomId',
        params: {
          roomId: room.value.id
        }
      });
    }
  };
</script>
