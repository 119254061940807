<template>
  <ui-swiper-actions-out
    :other-swiped="!isSwipedMenu"
    @swipe-start="onToggleSwipe(notification.id)"
    @swipe-end="onToggleSwipe($event ? notification.id : '')"
  >
    <template #default="{ swiped }">
      <common-notifications-base-card
        :notification="notification"
        :is-read="isRead"
        :class="{ 'rounded-r-none': swiped }"
        :loading="loading"
        @read="makeNotificationRead(true)"
      />
      <ui-dropdown-menu
        v-if="!isMobile"
        :menu-items="menuItems"
        class="absolute sm:top-1 -top-1 sm:right-2.5 right-0"
      />
    </template>
    <template
      v-if="isMobile"
      #action
    >
      <ui-button
        v-for="item in menuItems"
        :key="item.itemKey"
        rounded="none"
        v-bind="item.linkProps"
        class="last:rounded-r-sm first:rounded-l-none w-12 p-1.5 leading-none h-full"
      />
    </template>
  </ui-swiper-actions-out>
</template>

<script setup lang="ts">
  type Props = { notification: NotificationItem; isSwipedMenu?: boolean };
  const props = defineProps<Props>();
  const emits = defineEmits(['toggleSwipe', 'delete', 'read', 'close']);
  const apiRoutes = useApiRoutes();
  const isRead = computed(() => !!props.notification.read_at);
  const isMobile = useIsLessThan('sm');

  const menuItems = computed<NavLink[]>(() => {
    return [
      {
        itemKey: 'readNotification',
        linkProps: {
          variant: isMobile.value ? 'primary' : 'link',
          iconLeft: 'check',
          text: isMobile.value ? undefined : 'Read',
          iconExtend: !isMobile.value,
          hideSpace: true,
          disabled: isRead.value,
          onClick: () => {
            onToggleSwipe();
            makeNotificationRead();
          }
        }
      },
      {
        itemKey: 'DeleteNotification',
        linkProps: {
          variant: isMobile.value ? 'error' : 'error-link',
          iconLeft: 'delete',
          iconExtend: !isMobile.value,
          text: isMobile.value ? undefined : 'Delete',
          hideSpace: true,
          onClick: () => {
            onToggleSwipe();
            deleteNotification();
          }
        }
      }
    ];
  });
  const onToggleSwipe = (notification?: string) => {
    emits('toggleSwipe', notification);
  };

  const { handler, loading } = useHandlerErrorAndLoadingClient();

  const makeNotificationRead = async (close = false) => {
    if (close) {
      emits('close');
    }
    if (!isRead.value) {
      const { isError } = await handler(apiRoutes.notifications.update(props.notification.id));
      if (!isError) {
        emits('read', props.notification.id);
      }
    }
  };

  const deleteNotification = async () => {
    const { isError } = await handler(apiRoutes.notifications.delete(props.notification.id));

    if (!isError) {
      emits('delete', props.notification.id);
    }
  };
</script>
