const NotificationType = {
  MentionedInRoomMessage: 'MentionedInRoomMessage',
  RepliedInRoomMessage: 'RepliedInRoomMessage',
  RoomRequestApproved: 'RoomRequestApproved',
  RoomRequestRejected: 'RoomRequestRejected',
  RoomTypeChanged: 'RoomTypeChanged',
  RoomRequestCreated: 'RoomRequestCreated'
};

type TNotificationType = (typeof NotificationType)[keyof typeof NotificationType];

export { NotificationType, type TNotificationType };
