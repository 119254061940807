<template>
  <div class="lg:py-5 py-2.5 flex-1 flex flex-col">
    <div class="flex justify-end gap-2.5 mb-2.5 lg:px-5 px-2.5">
      <ui-button-async
        text="Read all"
        variant="link"
        underline="always"
        size="sm"
        :disabled="!notificationStore.isNotifications"
        :request="apiRoutes.notifications.update"
        @success="notificationStore.makeAllNotificationRead"
      />
      <ui-button-async
        text="Clear all"
        variant="error-link"
        underline="always"
        size="sm"
        :disabled="!notificationStore.isNotifications"
        :request="apiRoutes.notifications.delete"
        :check-confirm="confirmConfirmClearAll"
        @success="notificationStore.reset"
      />
    </div>
    <ui-virtual-scroller
      dependencies-key="id"
      :show-loader="notificationStore.isFirstLoad"
      :items="notificationStore.notifications"
      :loading="notificationStore.loading"
      :is-last-page="notificationStore.isLastPage"
      class="overflow-y-auto overflow-x-hidden lg:px-5 px-2.5 flex flex-col flex-1"
      wrap-item-classes="py-0.5"
      :class="listWrapClasses"
      @load-more="notificationStore.onLoadMore"
    >
      <template #item="{ item }">
        <common-notifications-card
          :notification="item"
          :is-swiped-menu="item.id === activeSwipe"
          @toggle-swipe="onToggleSwipe"
          @close="$emit('close')"
          @delete="notificationStore.deleteNotification"
          @read="notificationStore.makeNotificationRead"
        />
      </template>
      <template #empty>
        <ui-typography
          text="There are no notifications"
          class="text-center min-h-36 flex-1 flex items-center justify-center"
          variant="body"
        />
      </template>
    </ui-virtual-scroller>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    listWrapClasses?: ClassesType;
  };
  defineProps<Props>();
  defineEmits(['close']);
  const notificationStore = useNotificationStore();
  const apiRoutes = useApiRoutes();
  const activeSwipe = ref('');

  const { open: openConfirmClearAllModal } = useConfirmModal({});

  const confirmConfirmClearAll = () =>
    new Promise<boolean>(resolve => {
      openConfirmClearAllModal({
        title: 'Are you sure you want to delete all notifications?',
        confirmText: 'Yes, Delete',
        onSuccess: () => {
          resolve(true);
        }
      });
    });

  const onToggleSwipe = (notificationId: string) => {
    activeSwipe.value = notificationId;
  };

  onMounted(() => notificationStore.fetchNotifications());
</script>
